const $ = require('jquery');
import cookiesJs from 'js-cookie';
import $script from 'scriptjs';
const polyfillsRegexpQuote = require('./src/dep/polyfills/regexp.quote.js');
const polyfillsMatchMedia = require('./src//dep/polyfills/matchMedia.js');
const polyfillsObjectFitImages = require('./src/dep/polyfills/object-fit-images.js');
const observable = require('./src/dep/observable.js');

Window.prototype.$ = $;
global.$ = global.jQuery = $;

global.Cookies = cookiesJs;
Window.prototype.Cookies = cookiesJs;

global.$script = $script;
Window.prototype.$script = $script;

require('./src/api/app');

window.app.setConfig({
    lang: 'fr',
    colors: {
        primary: '#3643BA',
        secondary: '#ff5a5f',
    },
    breakpoints: {
        small: 500,
        medium: 750,
        intermediate: 1000,
        large: 1280,
    },
    pikaday: {
        firstDay: 1,
        format: 'DD/MM/YYYY',
        yearRange: [1900, 2035],
    },
});

require('./src/ext/jquery.responsivetable.js');
require('./src/ext/jquery.flickity.js');
require('./src/ext/jquery.dialog.js');
require('./src/ext/jquery.quicktip.js');
require('./src/ext/jquery.captureimgload.js');
require('./src/ext/jquery.uuid.js');
require('./src/ext/jquery.scrollbar.js');
require('./src/ext/jquery.megalink.js');
require('./src/ext/jquery.msg.js');
require('./src/ext/jquery.draggabilly.js');

require('./src/dict/fr.js');

require('./src/ui/blog-index.js');
require('./src/ui/travel-theme.js');
require('./src/ui/travel-tguide.js');
require('./src/ui/flat-home.js');
require('./src/ui/front-media-dialog.js');
require('./src/ui/link-obfuscator');
require('./src/ui/travel-spot.js');
require('./src/ui/flat-help.js');
require('./src/ui/flat-landingpage.js');
require('./src/ui/blog-post.js');
require('./src/ui/travel-search-engine.js');
require('./src/ui/travel-destination.js');
require('./src/ui/flat-inspiration.js');
require('./src/ui/travel-destinationspots.js');
