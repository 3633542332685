import debounce from 'lodash/debounce';

(function ($, app) {
    app.on('dom:ready', function ($container) {
        var $root = $('#travel-tguide', $container);

        if ($root.length === 0) return;

        // Navigation links
        (function () {
            var $links = $('#travel-tguide-links', $root),
                $linksInner = $('> .inner', $links);

            // Enable links dragging on small screens
            var $linksDragContainment;
            function refreshLinksDragContainment() {
                var linksWidth = $linksInner.width(),
                    overflow = Math.max(
                        0,
                        linksWidth - $linksInner.parent().width(),
                    );
                $linksDragContainment.css({
                    left: -1 * overflow + 'px',
                    width: linksWidth + overflow + 'px',
                });
                // make sure $linksInner has not moved outside containment while resizing windows
                var linksInnerPosition = $linksInner.position();
                if (
                    linksInnerPosition.left > 0 ||
                    linksInnerPosition.left < -1 * overflow
                ) {
                    $linksInner.css({
                        left:
                            linksInnerPosition.left > 0
                                ? '0'
                                : -1 * overflow + 'px',
                    });
                }
            }
            function toggleDraggableLinks() {
                if (
                    window.matchMedia(
                        '(min-width: ' + app.config.breakpoints.large + 'px)',
                    ).matches
                ) {
                    // large screens: disable drag mode
                    if ($linksDragContainment) {
                        $linksInner.draggabilly('destroy');
                        $linksDragContainment.remove();
                        $linksDragContainment = null;
                    }
                } else {
                    // small screens: enable drag mode or refresh containment
                    if ($linksDragContainment) {
                        refreshLinksDragContainment();
                    } else {
                        if ($linksInner.length !== 0) {
                            $linksDragContainment = $(
                                '<div class="travel-tguide-links-drag-containment"></div>',
                            )
                                .css({
                                    position: 'absolute',
                                    top:
                                        $linksInner.position().top +
                                        parseInt(
                                            $linksInner.css('marginTop'),
                                            10,
                                        ),
                                    height: $linksInner.height() + 'px',
                                })
                                .insertBefore($linksInner);
                            refreshLinksDragContainment();
                            $linksInner.draggabilly({
                                axis: 'x',
                                containment: $linksDragContainment[0],
                            });
                        }
                    }
                }
            }
            $(window).on('resize', debounce(toggleDraggableLinks, 100));
            toggleDraggableLinks();
        })();

        // Equipment
        (function () {
            var $equipment = $('> .travel-tguide-equipment', $root);

            // Megalink on products
            $('.travel-tguide-equipment-product', $equipment).megalink({
                selector: '.showmore > .link',
            });
        })();

        // Spots
        (function () {
            var $spots = $('> .travel-tguide-spots', $root);

            if ($spots.length === 0) return;

            var $list = $('> .inner > .list', $spots),
                $actions = $('> .inner > .actions', $spots);

            $list
                .flickity({
                    adaptiveHeight: true,
                    cellAlign: 'left',
                    groupCells: true,
                    prevNextButtons: false,
                    pageDots: false,
                })
                .captureImgLoad(function () {
                    $list.flickity('resize');
                });

            var flickity = $list.data('flickity');
            if (
                flickity != null &&
                flickity.slides != null &&
                flickity.slides.length > 1
            ) {
                function toggleActionButtons() {
                    $('> .action-prev', $actions).toggleClass(
                        'is-disabled',
                        flickity.selectedIndex < 1,
                    );
                    $('> .action-next', $actions).toggleClass(
                        'is-disabled',
                        flickity.selectedIndex >= flickity.slides.length - 1,
                    );
                }
                $list.on('select.flickity', toggleActionButtons);
                toggleActionButtons();
                $actions
                    .on('click', '.action-prev', function () {
                        $list.flickity('previous');
                    })
                    .on('click', '.action-next', function () {
                        $list.flickity('next');
                    });
                $('> .inner', $spots).addClass('has-actions');
            }

            // Megalink on spots
            $('.travel-tguide-spots-item', $spots).megalink({
                selector: '.heading > .link',
            });
        })();
    });
})(jQuery, window.app);
