import debounce from 'lodash/debounce';

(function ($) {
    // Event constants
    $.responsiveTable = {
        TOGGLE: 'responsiveTable:toggle',
    };

    // Plugin definition
    $.fn.responsiveTable = function (o) {
        if (typeof o !== 'string') {
            o = $.extend(
                {
                    class: 'responsive-table',
                    dataRows: 'tbody tr',
                    findHeader: findHeader,
                    headerSuffix: ':\xa0',
                },
                o,
            );
        }
        function findHeader($td, i) {
            return $td
                .closest('table')
                .children('thead')
                .first()
                .find('th:eq(' + i + ')')
                .text();
        }
        function fetchTHs($table) {
            // find TH related to each body TD
            if (o.findHeader) {
                $(o.dataRows, $table).each(function (i, tr) {
                    $('> td', tr).each(function (j, td) {
                        var $td = $(td),
                            header = o.findHeader($td, j);
                        if (header === true) {
                            // fallback to default handler
                            header = findHeader($td, j);
                        }
                        if (header) {
                            $td.attr('data-th', header + o.headerSuffix);
                        }
                    });
                });
            }
        }
        function overflowCheck($table, forceToggle) {
            // check if horizontal overflow > 0
            var br = 'responsive-breakpoint',
                parentWidth = $table.parent().width(),
                toggle = !!forceToggle;
            if (
                !$table.hasClass(o.class) &&
                parentWidth > 0 &&
                $table.outerWidth() - parentWidth > 0
            ) {
                $table.data(br, parentWidth);
                $table.addClass(o.class);
                toggle = true;
            } else if (
                $table.hasClass(o.class) &&
                parentWidth > $table.data(br)
            ) {
                $table.removeClass(o.class);
                $table.removeData(br);
                toggle = true;
            }
            toggle &&
                $table.trigger($.responsiveTable.TOGGLE, [
                    $table.hasClass(o.class),
                ]);
        }
        return this.each(function () {
            var $table = $(this),
                responsiveTable = $table.data('responsiveTable');
            switch (true) {
                // new instance
                case typeof o !== 'string' && !responsiveTable:
                    responsiveTable = {
                        uuid: $.uuid(),
                    };
                    $table.data('responsiveTable', responsiveTable);
                    fetchTHs($table);
                    overflowCheck($table, true);
                    $(window).on(
                        'resize.responsiveTable.' + responsiveTable.uuid,
                        debounce(function () {
                            overflowCheck($table);
                        }, 100),
                    );
                    break;
                // destroy existing instance
                case o === 'destroy' && !!responsiveTable:
                    $table.removeData('responsiveTable');
                    $(window).off(
                        'resize.responsiveTable.' + responsiveTable.uuid,
                    );
                    break;
                // refresh existing instance
                case o === 'refresh' && !!responsiveTable:
                    overflowCheck($table, true);
                    break;
            }
        });
    };
})(jQuery);
